@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Fabric Sans Web";
  src: url("./assets/fonts/fabric-sans/FabricSans-Regular.otf") format("otf"),
    url("./assets/fonts/fabric-sans/FabricSansWeb-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/fabric-sans/FabricSansWeb-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@layer base {
  :root {
    --color-white: 255, 255, 255;
    --color-black: 0, 0, 0;
    --color-primary: 25, 25, 40;
    --color-primary-gray: 168, 168, 168;
    --color-primary-blue: 22, 89, 238;
    --color-secondary: 255, 255, 255;
    --color-secondary-lightgray: 245, 245, 247;
    --color-secondary-midgray: 230, 230, 230;
  }
}

body {
  @apply font-fabric-sans antialiased;
}

@media screen and (min-width: 768px) {
  .hero-section {
    height: calc(100vh - 92px);
    min-height: 550px;
    max-height: 600px;
    overflow: hidden;
  }
}

.border-line {
  @apply border-b mb-24;
  --tw-border-opacity: 1;
  border-color: rgba(var(--color-primary-blue), var(--tw-border-opacity));
}
.border-line-team {
  @apply mt-28 mb-24;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(var(--color-primary-blue), var(--tw-border-opacity));
}
.person-img {
  /* width: 310px; */
  width: 100%;
  height: 360px;
}
.inv-border-line {
  border-bottom-width: 1px;
  margin-bottom: 23.78px;
  --tw-border-opacity: 1;
  border-color: rgba(var(--color-primary-blue), var(--tw-border-opacity));
}
.find-more {
  background-image: url("./assets/images/find.png");
  background-size: 100% 100%;
}
